import React from "react"
import { FooterText } from "../styles/TextStyles"

const linkStyle = {
  textDecoration: "none",
  color: "#c4b3e2",
}

const List = ({ text, navLink }) => {
  const listStyle = {
    margin: "0.5rem 0",
  }
  return (
    <li style={listStyle}>
      <FooterText>
        <a href={navLink} style={linkStyle} target="_blank">
          {text}
        </a>
      </FooterText>
    </li>
  )
}

export default List

import styled from "styled-components"

export const H1 = styled.h1`
  font-weight: normal;
  font-size: 5rem; //80px
  text-transform: uppercase;
  letter-spacing: 0.3125rem; //5px
  line-height: 100%;
  overflow: hidden;

  @media (min-width: 1121px) and (max-width: 1279px) {
    font-size: 4.4rem;
  }
  @media (min-width: 1024px) and (max-width: 1120px) {
    font-size: 3.9rem;
  }
  @media (max-width: 1023px) {
    font-size: 3rem;
    line-height: 120%;
  }
  @media (max-width: 480px) {
    font-size: 1.8rem;
    line-height: 120%;
  }
`
export const H2 = styled.h2`
  font-weight: 400;
  font-size: 1.625rem; //26px
  text-transform: uppercase;
  letter-spacing: 0.3125rem; //5px
  line-height: 100%;
  color: #e616ff;

  @media (max-width: 480px) {
    text-align: center;
    /* font-size: 0.8125rem; //13px
    font-size: 0.8125rem;
    text-align: center;
    letter-spacing: 0.3rem; //5px */
  }
`
export const H3 = styled.h3`
  font-family: "Audiowide", cursive;
  font-weight: 400;
  font-size: 1.375rem; //22px
  text-transform: uppercase;
  letter-spacing: 0.3125rem; //5px
  line-height: 120%;
  color: #e616ff;

  @media (max-width: 480px) {
    font-size: 0.75rem;
    line-height: 0.9rem;
    text-align: center;
    letter-spacing: 0.3rem; //5px
  }
`
export const H4 = styled.h4`
  font-weight: 400;
  font-size: 1.125rem; //18px
  text-transform: uppercase;
  letter-spacing: 0.125rem; //2px
  line-height: 180%;
  color: #e616ff;
`
export const ComingSoon = styled.h4`
  font-family: "Nova Mono", monospace;
  font-weight: 400;
  font-size: 1.125rem; //18px
  text-transform: uppercase;
  letter-spacing: 0.125rem; //2px
  line-height: 180%;
  color: #e616ff;
`

export const BodyMain = styled.p`
  font-weight: normal;
  font-size: 1rem; //16px
  line-height: 180%;
  letter-spacing: 2px;

  @media (max-width: 480px) {
    font-size: 0.8125rem;
    line-height: 150%;
  }
`
export const FooterText = styled.p`
  font-weight: 700;
  font-size: 0.875rem; //14px
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #c4b3e2;
  text-align: center;
`
export const Caption = styled.p`
  font-weight: 500;
  font-size: 0.75rem; //12px
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 180%;
  color: #c4b3e2;

  @media (max-width: 550px) {
    text-align: center;
    /* font-size: 0.8125rem; //13px
    font-size: 0.8125rem;
    text-align: center;
    letter-spacing: 0.3rem; //5px */
  }
`

export const LegalText = styled.p`
  font-weight: 400;
  font-size: 0.625rem; //10px
  line-height: 180%;
  letter-spacing: 1px;
  color: #c4b3e2;
`

import React from "react"
import styled from "styled-components"
import List from "../list/List"
import { Caption } from "../styles/TextStyles"

function Footer(props) {
  return (
    <Wrapper displaytype={props.displaytype}>
      <NavWrapper>
        <Nav>
          <List
            text="&#64;norvinacosmetics"
            navLink="https://www.instagram.com/norvinacosmetics/"
          />
          <List
            text="&#64;norvina"
            navLink="https://www.instagram.com/norvina/"
          />
          <List
            text="&#64;anastasiabeverlyhills"
            navLink="https://www.instagram.com/anastasiabeverlyhills/"
          />
        </Nav>
      </NavWrapper>
      <LegalWrapper>
        <Legal>
          <List
            text="Terms &amp; Conditions"
            navLink="https://www.anastasiabeverlyhills.com/terms.html"
          />
          <List
            text="Privacy Policy"
            navLink="https://www.anastasiabeverlyhills.com/privacy-policy.html"
          />
          {props.show === true ? (
            <List text="Official Rules" navLink="/rules" />
          ) : (
            ""
          )}
        </Legal>
        <CopyRight>
          <Caption>
            Copyright &copy; 2021 Anastasia Beverly Hills. All rights reserved.
          </Caption>
        </CopyRight>
      </LegalWrapper>
    </Wrapper>
  )
}

export default Footer

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 100%;
  height: 250px;
  width: 100%;
  max-width: 1680px;
  margin: 96px 0 0 0;
  grid-area: footer;
  overflow: visible;
  @media (max-width: 768px) {
    margin: 10px 0 0 0;
  }
  @media (max-width: 480px) {
    height: 400px;
    margin: 10px 0 0 0;
  }
  @media (max-width: 1200px) and (orientation: landscape) {
    display: ${props => props.displaytype};
  }
`

const NavWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 1680px;
  height: 100px;
  border-top: solid 1px #e616ff;
  /* border-bottom: solid 1px #e616ff; */
  @media (max-width: 767px) {
    padding: 2rem 3rem;
    height: auto;
  }
`
const Nav = styled.ul`
  width: 80%;
  max-width: 986px;
  display: flex;
  justify-content: space-evenly;
  flex: auto;
  align-items: center;
  @media (max-width: 767px) {
    margin: 0 1rem;
    padding: 0 2rem;
    flex-wrap: wrap;
  }
`
const LegalWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  /* flex: auto; */
  align-items: center;

  @media (max-width: 480px) {
    width: 80%;
  }
`
const Legal = styled.ul`
  width: 50%;
  max-width: 986px;
  display: flex;
  justify-content: space-evenly;
  flex: auto;
  flex-direction: row;
  align-items: center;

  @media (max-width: 1023px) {
    width: 70%;
    margin-top: 0;
  }
  @media (max-width: 480px) {
    flex-direction: column;
    margin-top: 0;
  }
`
const CopyRight = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;

  @media (max-width: 767px) {
    padding: 0 2.5rem;
  }
  @media (max-width: 550px) {
    padding: 0 90px;
  }
`

import React from "react"
import styled from "styled-components"

function Nav(props) {
  return (
    <Wrapper type={props.displaytype}>
      <Logo src="/images/nav/logo.svg" alt="Norvina Logo" />
    </Wrapper>
  )
}

export default Nav

const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
  grid-area: nav;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1680px;
  height: 110px;

  @media (max-width: 1023px) {
    max-width: 1023px;
    width: 100%;
    height: auto;
  }
  @media (max-width: 1024px) and (orientation: landscape) {
    display: ${props => props.displaytype};
  }
`
const Logo = styled.img`
  width: 162px;
  height: auto;
`
